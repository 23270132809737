<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="fixed">
        <adv-search
          @search="advSearch"
          :is_date_range="true"
          :is_service="true"
          :is_status="true"
          :is_job_report="true"
          :is_location="true"
          :reportData="this.excelreports"
          :titleworksheet="this.titleworksheet"
          :json_fields="this.json_fields"
          :header="this.header"
          :report_name="'mobile_service_report.xls'"
        />
      </div>
      <div
        class="row"
        style="margin-top: 4%"
        v-if="reports.length > 0 && !getLoadingData"
      >
        <div class="col-md-12">
          <v-table :columns="columns">
            <tr v-for="(item, index) in reports" :key="index">
              <td>
                {{ ++index }}
              </td>
              <td v-if="search.location == 'all'">
                {{ item.location_name }}
              </td>
              <td>{{ item.job_title }}</td>
              <td>{{ item.applicant }}</td>
              <td>{{ item.service }}</td>
              <td>{{ item.service_status }}</td>
              <td>{{ item.payment_status }}</td>
              <td v-if="item.schedule_date != null">
                {{ dateFormat(item.schedule_date) }}
              </td>
              <td v-if="item.schedule_date == null">N/A</td>
              <td class="text-right">
                $ {{ item.commission | toFixedTwoDigit }}
              </td>
              <td class="text-right">$ {{ item.amount | toFixedTwoDigit }}</td>
            </tr>
            <tr>
              <td :colspan="search.location == 'all' ? 8 : 7">
                <b>Grand Total</b>
              </td>
              <td class="text-right">
                <b>
                  $
                  {{ total_commission | toFixedTwoDigit }}</b
                >
              </td>
              <td class="text-right" style="width: 10%">
                <b>
                  $
                  {{ grand_total | toFixedTwoDigit }}</b
                >
              </td>
            </tr>
          </v-table>
        </div>
      </div>
      <div class="row" v-if="reports.length < 1 && !getLoadingData && !load">
        <div class="col-md-12 text-center">
          <h4>no report(s) found</h4>
        </div>
      </div>
      <div class="row" v-if="load" style="opacity: 0.8">
        <div class="col-md-12 text-center">
          <h4>Please select the criteria</h4>
        </div>
      </div>

      <div class="row move-below" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  FETCH_CLIENTS,
  FETCH_SERVICES,
  FETCH_LOCATION_JOB_WISE_REPORT,
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import vTable from "@/includes/table/Datatable";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import AdvSearch from "@/includes/search/AdvSearch";
// import * as PDF417 from "pdf417-generator";
export default {
  components: {
    vTable,
    vDataLoader,
    AdvSearch,
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "getClients",
      "getServices",
      "getCompanies",
      "getSearchDate",
    ]),
    grand_total: function () {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach((element) => {
          total += element.amount;
        });
      }
      return total;
    },
    total_commission: function () {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach((element) => {
          total += element.commission;
        });
      }
      return total;
    },
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
  },
  data() {
    return {
      search: {
        service: "all",
        company: "all",
        date_from: "",
        date_to: "",
        service_status: "all",
        payment_status: "all",
        search_text: "",
      },

      getLoadingData: false,
      columns: [
      {
          name: "S.No",
          width: "",
          label: "S.No",
          class: ""
        },
        {
          name: "location",
          width: "",
          label: "Location",
          class: "",
        },
        {
          name: "Job Title",
          width: "",
          label: "Job Title",
          class: "",
        },
        {
          name: "Applicant Name",
          width: "",
          label: "Applicant Name",
          class: "",
        },
        {
          name: "Service Name",
          width: "",
          label: "Service ",
          class: "",
        },
        {
          name: "Service Status",
          width: "",
          label: "Service Status",
          class: "",
        },

        {
          name: "Payment Status",
          width: "",
          label: "Payment Status",
          class: "",
        },
        {
          name: "Scheduled Date",
          width: "",
          label: "Scheduled Date",
          class: "",
        },
        {
          name: "commission",
          width: "",
          label: "Commission",
          class: "text-right",
        },
        {
          name: "amount",
          width: "",
          label: "Amount",
          class: "text-right",
        },
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "Report",
          route: "",
        },
        {
          id: 2,
          title: "Location Job Report",
          route: "",
        },
      ],
      reports: [],
      header: [],
      load: true,
      excelreports: [],
      titleworksheet: "",
      json_fields: {
        "Location	": "location_name",
        "Job Title": "job_title",
        "Applicant Name": "applicant",
        Service: "service",
        "Service Status": "service_status",
        "Payment Status": "payment_status",
        "Scheduled Date": "schedule_date",
        Commission: "commission",
        Amount: "amount",
      },
    };
  },

  mounted() {
    this.$store.dispatch(FETCH_CLIENTS);
    let serviceOutlet = this.currentUser.u.is_client ? "self" : "all";
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_SERVICES, serviceOutlet);
  },

  methods: {
    advSearch(value) {
      this.getLoadingData = true;
      this.load = false;
      let { header, search, footer } = value;
      this.header = header;
      this.search = { ...search };
      this.tablereset();

      this.$store
        .dispatch(FETCH_LOCATION_JOB_WISE_REPORT, search)
        .then((res) => {
          this.getLoadingData = false;
          this.reports = res.data;
          this.titleworksheet = search.date_from + " - " + search.date_to;
          this.excelreports = [...res.data];
          let data = [...res.data];
          let total = 0;
          let commission = 0;
          if (data.length != 0) {
            data.forEach((element) => {
              total += element.amount;
              commission += element.commission;
            });
            let gtotal = {
              schedule_date: "Grand Total",
              service: "",
              job_title: "",
              applicant: "",
              location_name: "",
              service_status: "",
              payment_status: "",
              payment_method: "",
              amount: total,
              commission: commission,
            };
            this.excelreports.push(gtotal);
          }
          let foo = {
            schedule_date: "",
            service: "",
            job_title: "",
            applicant: "",
            location_name: "",
            service_status: "",
            payment_status: "",
            payment_method: "",
            amount: footer[1],
            commission: footer[0],
          };
          this.excelreports.push(foo);
        })
        .catch((err) => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    },
    tablereset() {
      if (this.search.location != "all") {
        this.columns = [
        {
          name: "S.No",
          width: "",
          label: "S.No",
          class: ""
        },
          {
            name: "Job Title",
            width: "",
            label: "Job Title",
            class: "",
          },
          {
            name: "Applicant Name",
            width: "",
            label: "Applicant Name",
            class: "",
          },

          {
            name: "Service Name",
            width: "",
            label: "Service ",
            class: "",
          },
          {
            name: "Service Status",
            width: "",
            label: "Service Status",
            class: "",
          },

          {
            name: "Payment Status",
            width: "",
            label: "Payment Status",
            class: "",
          },
          {
            name: "Scheduled Date",
            width: "",
            label: "Scheduled Date",
            class: "",
          },
          {
            name: "commission",
            width: "",
            label: "Commission",
            class: "text-right",
          },
          {
            name: "amount",
            width: "",
            label: "Amount",
            class: "text-right",
          },
        ];
        this.json_fields = {
          "Job Title": "job_title",
          "Applicant Name": "applicant",
          Service: "service",
          "Service Status": "service_status",
          "Payment Status": "payment_status",
          "Scheduled Date": "schedule_date",
          Commission: "commission",
          Amount: "amount",
        };
      } else {
        this.json_fields = {
          "Location	": "location_name",
          "Job Title": "job_title",
          "Applicant Name": "applicant",
          Service: "service",
          "Service Status": "service_status",
          "Payment Status": "payment_status",
          "Scheduled Date": "schedule_date",
          Commission: "commission",
          Amount: "amount",
        };
        this.columns = [
        {
          name: "S.No",
          width: "",
          label: "S.No",
          class: ""
        },
          {
            name: "location",
            width: "",
            label: "Location",
            class: "",
          },
          {
            name: "Job Title",
            width: "",
            label: "Job Title",
            class: "",
          },
          {
            name: "Applicant Name",
            width: "",
            label: "Applicant Name",
            class: "",
          },
          {
            name: "Service Name",
            width: "",
            label: "Service ",
            class: "",
          },
          {
            name: "Service Status",
            width: "",
            label: "Service Status",
            class: "",
          },

          {
            name: "Payment Status",
            width: "",
            label: "Payment Status",
            class: "",
          },
          {
            name: "Scheduled Date",
            width: "",
            label: "Scheduled Date",
            class: "",
          },
          {
            name: "commission",
            width: "",
            label: "Commission",
            class: "text-right",
          },
          {
            name: "amount",
            width: "",
            label: "Amount",
            class: "text-right",
          },
        ];
      }
    },
    refreshData() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15,
      };
    },

    statusColor(status) {
      if (status == "P") {
        return "warning";
      }
      if (status == "C") {
        return "success";
      }
      if (status == "U") {
        return "info";
      }
      if (status == "Cancel") {
        return "danger";
      }
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      //   this.appData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      //   this.appData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      //   this.appData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      //   this.appData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      //   this.appData();
    },
  },
};
</script>
